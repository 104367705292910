<template>
  <div class="main-content">
    <div class="row w-100">
      <div class="col-12">
        <b-card>
          <b-card-body>
            <b-form @submit.prevent="saveSetting">
              <div class="row">
                <div class="col-12">
                  <b-form-group id="beneficiary_name_l" label="Beneficiary Name">
                    <b-form-input id="beneficiary_name" v-model="form.beneficiary_name" placeholder="Enter Beneficiary Name" />
                  </b-form-group>
                </div>
                <div class="col-12">
                  <b-form-group id="beneficiary_address_l" label="Beneficiary Address">
                    <b-form-input id="beneficiary_address" v-model="form.beneficiary_address" placeholder="Enter Beneficiary Address" />
                  </b-form-group>
                </div>
                <div class="col-12">
                  <b-form-group id="bank_name_l" label="Bank Name">
                    <b-form-input id="bank_name" v-model="form.bank_name" placeholder="Enter Bank Name" />
                  </b-form-group>
                </div>
                <div class="col-12">
                  <b-form-group id="bank_address_l" label="Bank Address">
                    <b-form-input id="bank_address" v-model="form.bank_address" placeholder="Enter Bank Address" />
                  </b-form-group>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <b-form-group id="bank_country_l" label="Bank Country">
                        <model-select v-model="form.country_id" :options="countryOptions" />
                      </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                      <b-form-group id="bank_state_l" label="Bank State">
                        <model-select v-model="form.state_id" :options="stateOptions" />
                      </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                      <b-form-group id="bank_city_l" label="Bank City">
                        <model-select v-model="form.city_id" :options="cityTypeOptions" />
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <b-form-group id="account_number_l" label="Account Number">
                    <b-form-input id="account_number" v-model="form.account_number" placeholder="Enter Account Number" />
                  </b-form-group>
                </div>
                <div class="col-12">
                  <b-form-group id="iabn_ifsc_l" label="IBAN/IFSC">
                    <b-form-input id="iabn_ifsc" v-model="form.iabn_ifsc" placeholder="Enter IBAN/IFSC" />
                  </b-form-group>
                </div>
                <div class="col-12">
                  <b-form-group id="swift_code_l" label="Swift Code/BIC/Routing Number">
                    <b-form-input id="swift_code" v-model="form.swift_code" placeholder="Enter Swift Code/BIC/Routing Number" />
                  </b-form-group>
                </div>
                <div class="col-12">
                  <b-button variant="success" type="submit">Save</b-button>
                </div>
              </div>
            </b-form>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>


<script>
import { isEmpty } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { ModelSelect } from 'vue-search-select'
import { useUserUi } from '@/views/users/useUser'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'

export default {
  name: 'PayoutsIndex',

  components: {
    ModelSelect,
  },

  data: () => ({
    form: {
      beneficiary_name: null,
      beneficiary_address: null,
      bank_name: null,
      bank_address: null,
      account_number: null,
      iabn_ifsc: null,
      swift_code: null,
      country_id: null,
      state_id: null,
      city_id: null,
    },
    cityTypeOptions: [],
    countryOptions: [],
    stateOptions: [],
  }),

  computed: {
    ...mapGetters('affilation', ['payouts']),
  },

  methods: {
    ...mapActions('affilation', ['getPayouts', 'updatePayout']),
    async saveSetting() {
      showLoader()
      await this.updatePayout(this.form)
      hideLoader()
    },

    async getCountryList() {
      const { countryList } = useUserUi()
      countryList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.countryOptions = data.responseData.map(x => ({
              text: x.country_name,
              value: x.id,
            }))
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    onStateChange() {
      this.cityTypeOptions = []
      const { getCityByStateCountry } = useUserUi()
      showLoader()
      getCityByStateCountry(`country_id=${this.form.country_id}&state_id=${this.form.state_id}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.cityTypeOptions = data.responseData.map(x => ({
              text: x.city_name,
              value: x.id,
            }))
            // eslint-disable-next-line eqeqeq
            if (!data.responseData.find(x => x.id == this.form.city_id)) {
              this.form.city_id = ''
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    onCountryChange() {
      this.cityTypeOptions = []
      this.stateOptions = []
      const { getStateByCountry } = useUserUi()
      showLoader()
      getStateByCountry(this.form.country_id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.stateOptions = data.responseData.map(x => ({
              text: x.state_name,
              value: x.id,
            }))
            // eslint-disable-next-line eqeqeq
            if (!data.responseData.find(x => x.id == this.form.state_id)) {
              this.form.state_id = ''
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },

  mounted: async function () {
    await this.getCountryList()
    this.getPayouts()
  },

  watch: {
    payouts: {
      handler(v) {
        if (!isEmpty(v)) {
          const first = v.find((i, idx) => idx === 0)
          if (!isEmpty(first)) {
            Object.keys(this.form).forEach(f => {
              this.form[f] = first[f]
            })
          }
        }
      },
    },
    'form.country_id': {
      immediate: true,
      deep: true,
      handler() {
        if (this.form.country_id) {
          this.onCountryChange()
        }
      },
    },
    'form.state_id': {
      immediate: true,
      deep: true,
      handler() {
        if (this.form.state_id) {
          this.onStateChange()
        }
      },
    },
  },
}
</script>